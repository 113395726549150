import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import KatrequeImg from "../../static/images/katreque.jpg";
//import VorinImg from "../../static/images/vorin.png"
//import GustavinImg from "../../static/images/gustavin.jpg"
//import MayaraImg from "../../static/images/mayara.jpeg"

function About(props) {
    const [mainColor, setMainColor] = useState(props.mainColor);
    const [secondaryColor, setSecondaryColor] = useState(props.secondaryColor);

    useEffect(() => {
        setMainColor(props.mainColor);
        setSecondaryColor(props.secondaryColor);
    }, [props.mainColor, props.secondaryColor])

    return (
        <div>
            <div style={{backgroundColor: mainColor}}>
                <div className="d-flex justify-content-center align-items-center animated delay-0s fadeInDown" style={{backgroundColor: "white", minHeight: "250px", zIndex: "1"}}>
                    <div className="w-100 text-center animated fadeIn delay-1s">
                        <h1 className="bungee-font">Conheça a Kat Devs</h1>
                    </div>
                </div>
                <div className="p-4 container">
                    <div style={{fontSize: "large", color: "white"}}>
                        <p>
                            A <strong>Kat Devs</strong> nasceu da vontade e esforço do seu fundador, <strong>Renan "Katreque" Verissimo</strong>, em fazer da cultura de Games e 
                            eSports algo ainda maior, seja no Brasil ou no mundo. Ver e acompanhar o desenvolvimento desta comunidade, somado a toda 
                            experiência negativa que teve nas empresas em que trabalhou durante sua formação, motivou-o a criar algo em que pudesse contribuir para o cenário,
                            enquanto segue princípios como <strong>transparência</strong>, <strong>comunidade</strong> e <strong>divertimento</strong>.
                        </p>
                        <p>
                            Desta forma, no dia <strong>11 de janeiro de 2020</strong>, a Kat Devs foi fundada. Reunindo as pessoas certas que acreditaram na ideia, 
                            começamos nossa jornada em busca de nossos objetivos e realizações. Iniciando com dois projetos da casa, o <strong>futuro que nos aguarde</strong>!
                        </p>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-2 mb-4" style={{paddingTop: "40px", backgroundColor: "white", color: secondaryColor}}>
                <div>
                    <h1 className="bungee-font text-center mt-4 mb-4">Time</h1>
                </div>
                <div className="container" style={{fontSize: "large"}}>

                    <div className="card-deck mx-auto" style={{width: "18em"}}>
                        <div className="card">
                            <img src={KatrequeImg} className="card-img-top" alt="Renan Verissimo" />
                            <div className="card-body text-center">
                                <div className="d-flex flex-column">
                                    <small style={{marginBottom: "-0.5em"}}>Renan Verissimo</small>
                                    <span style={{fontSize: "1.75em"}}>
                                        <strong>Katreque</strong>
                                    </span>
                                    <small className="mt-2 mb-3">
                                        <strong style={{color: mainColor}}>Founder & CEO</strong>
                                    </small>
                                </div>
                                <div>
                                    <a style={{color: mainColor}} href="https://www.linkedin.com/in/katreque" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={["fab", "linkedin"]} size="lg" />
                                    </a>
                                    <a style={{color: mainColor}} href="https://twitter.com/katreque" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={["fab", "twitter"]} size="lg" className="ms-2 me-2" />
                                    </a>
                                    <a style={{color: mainColor}} href="https://www.instagram.com/katreque" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/*
                        <div className="card">
                            <img src={VorinImg} className="card-img-top" alt="Gustavo de Almeida" />
                            <div className="card-body text-center">
                                <small>Vorin</small>
                                <h5 className="card-title">Gustavo de Almeida</h5>
                                <div style={{color: mainColor}}>
                                    <small>
                                        <p>Lead Game Designer</p>
                                    </small>
                                    <a style={{color: mainColor}} href="https://www.linkedin.com/in/gustavo-de-almeida-932298164" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={["fab", "linkedin"]} size="lg" />
                                    </a>
                                    <a style={{color: mainColor}} href="https://www.instagram.com/gusvorin" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" className="ml-2 mr-2"/>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <img src={GustavinImg} className="card-img-top" alt="Gustavo Chermout" />
                                <div className="card-body text-center">
                                    <small>Gustavin</small>
                                    <h5 className="card-title">Gustavo Chermout</h5>
                                    <div style={{color: mainColor}}>
                                        <small>
                                            <p>Head of Development</p>
                                        </small>
                                        <a style={{color: mainColor}} href="https://www.linkedin.com/in/gustavo-chermout-0a7734a1" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={["fab", "linkedin"]} size="lg" />
                                        </a>
                                    </div>
                                </div>
                        </div>

                        <div className="card">
                            <img src={MayaraImg} className="card-img-top" alt="Mayara Miriam" />
                            <div className="card-body text-center">
                                <small>May</small>
                                <h5 className="card-title">Mayara Miriam</h5>
                                <div style={{color: mainColor}}>
                                    <small style={{color: mainColor}}>
                                        <p>Chief People Officer</p>
                                    </small>
                                    <a style={{color: mainColor}} href="https://www.linkedin.com/in/mayarambcorrea" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={["fab", "linkedin"]} size="lg" className="mr-2" />
                                    </a>
                                    <a style={{color: mainColor}} href="https://www.instagram.com/may_miriam_" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;