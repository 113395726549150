import React from 'react';

import Layout from "../components/layout.js";
import About from "../components/about.js";
import SEO from "../components/SEO.js";

function QuemSomos () {
    return (
        <Layout>
            <SEO title="Quem Somos" description={`Quem é a Kat Devs? A Kat Devs é uma pequena empresa de desenvolvimento, criando games e soluções para o mercado de jogos e eSports.`} />
            <About />
        </Layout>
    );
}

export default QuemSomos;